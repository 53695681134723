
/* ==========================================================================
   NAV
   ========================================================================== */

.subnav {
  @media #{$desktop} {
    position: absolute;
    left: 50%;
    top: 78px;
    right: 0;
    width: 220px;
    margin-left: -110px;
    z-index: 99;
    box-shadow: 0px 1px 3px rgba(214, 214, 214, .5);
  }

  &__list {
    background-color: color-theme('white');
    list-style: none;
    padding: 0 16px;
    margin: 0;
  }

  &__item {
    font-family: font-theme('text');
    @include text-setting('s');
    color: color-theme('black');
    text-align: center;
    padding: 12px 0;

    @media #{$tablet} {
      border-bottom: 1px solid color-theme('grey','light');
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    text-decoration: none;
    color: color-theme('grey','darker');

    &.no-touch:hover {
      color: color-theme('primary');
    }
  }
}


