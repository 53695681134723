/* ==========================================================================
   PAGINATION
   ========================================================================== */

.numbering {
  @extend %clearfix;
  margin: 32px auto 0;
  text-align: center;

  @media #{$tablet} {
    text-align: left;
  }

  &__list {
    @extend %no-list;
    font-size: 0;
    margin: -4px;

    @media #{$tablet} {
      float: left;
    }

    &__item {
      @include text-setting('xs');
      display: inline-block;
      padding: 4px;
    }

    &__btn {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      //border-radius: 20px;
      border: 1px solid color-theme('primary');
      color: color-theme('primary', 'active');
      background: color-theme('white');
      /* font-family: font-theme('head', 'medium'); */

      &.no-touch:hover {
        color: color-theme('white');
        background: color-theme('primary');
      }

      &.no-touch:active,
      &:active {
        background: color-theme('primary', 'sky');
      }

      &.is-active {
        color: color-theme('white');
        background: color-theme('primary');
      }
    }

    &__ellipsis {
      @extend .numbering__list__btn;
      background: color-theme('white');
      color: color-theme('black');
      border: none;
    }
  }

  &__info {
    @include text-setting('xs');
    color: color-theme('grey', 'darker');
    padding: 12px 0 0;

    @media #{$tablet} {
      float: right;
      padding: 16px 0;
    }
  }
}
