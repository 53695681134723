/* ==========================================================================
   HEADER
   ========================================================================== */

.header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  background: color-theme('white');
  box-shadow: 0px 1px 3px rgba(214, 214, 214, .5);

  &__wrapper {
    @extend %clearfix;
    @extend %wrapper;
    padding: 16px;
    
    @media #{$desktop} {
      padding: 22px 16px;
    }
  }

  &__left {
    position: relative;
    z-index: 59;
    float: left;
    font-size: 0;
    text-align: center;
  }

  &__logo {
    display: inline-block;
    vertical-align: middle;

    @media #{$mobile} {
      padding-left: 8px;
    }

    @media #{$desktop} {
      padding-left: 0;
    }

    &__link {
      display: block;
      overflow: hidden;

      width: 120px;

      @media #{$tablet} {
        width: 136px;
        max-width: 100%;
        overflow: visible;
        margin-left: 0px;
      }
    }

    &__img {
      width: 100%;
    }
  }

  &__right {
    float: right;

    @media #{$tablet} {
    margin: 6px 0;
    }
  }

  &__cta {
    vertical-align: middle;
    padding: 8px 20px;

    @media #{$tablet} {
      padding: 8px 32px;
      vertical-align: top;
    }
  }

  &__btn {
    display: inline-block;
    font-family: font-theme('text', 'regular');
    color: color-theme('black');
    text-decoration: none;
    line-height: 24px;
    padding: 4px 0;
    margin-left: 16px;
    min-width: 24px;
    text-align: center;
    @include text-setting('s');

    @media #{$desktop} {
      margin-left: 16px;
    }

    &:first-child {
      margin-left: 0;
    }

    /* &:hover {
      
    } */

    &--nav {
      display: inline-block;
      vertical-align: middle;

      @media #{$desktop} {
        display: none;
      }
    }
  }

  &__nav {
    align-self: center;

    &__btn {
      display: block;
      width: 32px;
      height: 32px;
      text-align: center;
      padding: 8px;
      border-radius: 16px;
      transition: .15s;
      font-size: 0;

      &.no-touch:hover {
        background: color-theme('grey', 'light');
      }

      &.no-touch:active,
      &:active {
        opacity: .7;
      }

      &__lines {
        position: relative;
        width: 16px;
        height: 16px;
        display: inline-block;
      }

      &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background: color-theme('black');
        transition: .15s;

        &--top {
          top: 0;
          left: 0;
        }

        &--middle {
          top: 50%;
          left: 0;
          margin-top: -1px;
        }

        &--bottom {
          bottom: 0;
          left: 0;
        }
      }

      // States

      &.is-active {
        .header__nav__btn {
          &__line {
            &--top {
              transform: translateY(7px) rotate(45deg);
            }

            &--middle {
              transform: scaleX(0);
            }

            &--bottom {
              transform: translateY(-7px) rotate(-45deg);
            }
          }
        }
      }

      &--menu {
        @media #{$tablet} {
          display: none;
        }
      }

      &--search {
        display: none;

        @media #{$tablet} {
          display: block;
        }
      }
    }
  }

}

