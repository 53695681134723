/* ==========================================================================
   TABS
   ========================================================================== */

  .tabs {
    font-size: 0;

    @media #{$tablet} {
      margin: 0 auto;
    }

    &__nav {
      @media #{$tablet} {
        margin: 0 auto;
        padding: 0;
        white-space: inherit;
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 32px 16px 32px 0;
      }

      @media #{$desktop} {
        padding: 32px 0;
      }

      &__list {
        @extend %no-list;
      }

      &__item {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__btn {
        display: block;
        text-align: left;

        &.is-active {
          color: color-theme('primary');
        }
      }
    }

    &__content {
      @extend %child-no-margin;
      display: none;
      margin-top: 32px;

      @media #{$tablet} {
        margin-top: 0;
      }

      // States

      &.is-active {
        display: block;

        @media #{$tablet} {
          display: inline-block;
          vertical-align: top;
          width: 80%;
        }
      }
    }
  }
