/* ==========================================================================
   BREADCRUMB
   ========================================================================== */

.breadcrumb {
  width: 100%;
  background-color: color-theme('grey','lighter');
  
  &__wrapper {
    @extend %clearfix;
    @extend %wrapper;
    padding: 16px;
    max-width: 300px;
    overflow-x: scroll;

    @media #{$tablet} {
      max-width: 1180px;
      overflow-x: initial;
    }
    
    @media #{$desktop} {
      padding: 16px 0;
    }
  }

  &__list {
    @extend %no-list;
    width: 100%;
    white-space: nowrap;
  }

  &__item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 16px;


    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: color-theme('grey','dark');
      top: 50%;
      margin-top: -2px;
      right: -2px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      
      &:before {
        display: none;
      } 

      .breadcrumb__btn { color: color-theme('primary'); }
    }
  }

  &__btn {
    display: block;
    padding: $bl 0;
    font-family: font-theme('text');
    @include text-setting('s');
    text-decoration: none;
    color: color-theme('grey','dark');
    position: relative;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
