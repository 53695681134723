/* ==========================================================================
   TEXTS
   ========================================================================== */

h1,
h2,
h3,
h4,
h5 {
  margin: ($bl * 4) 0;
  font-weight: normal;

  b,
  strong {
    font-family: font-theme();
  }
}

p,
ul,
ol,
dl {
  margin: ($bl * 4) 0;
}

b,
strong {
  font-family: font-theme();
}


.title {
  @include text-setting('l');
  text-align: left;
  font-family: font-theme('text','bold');
  color: color-theme('black');
  margin: 0 0 24px;
  position: relative;
  padding-bottom: 16px;

  @media #{$tablet} { @include text-setting('xl'); }

  &__lines {
    @extend %no-list;
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 147px;
  }

  &__line {
    height: 6px;
    display: inline-block;
    border-radius: 100px;

    &--1 {
      width: 100px;
      background-color: color-theme('primary');
    }

    &--2 {
      width: 6px;
      background-color: color-theme('primary');
      margin-left: 2px;
    }

    &--3 {
      width: 6px;
      background-color: color-theme('primary','light');
      margin-left: 2px;
    }

    &--4 {
      width: 6px;
      background-color: color-theme('primary','lighter');
      margin-left: 2px;
    }
  }


  &--small {
    @include text-setting('m');
    margin: 0 0 16px;

    @media #{$tablet} { @include text-setting('xm'); }

    .title__lines {
      bottom: 0;
      width: 100px;
    }

    .title__line {
      height: 4px;

      &--1 {
        width: 70px;
      }

      &--2 {
        width: 4px;
        margin-left: 1px;
      }

      &--3 {
        width: 4px;
        margin-left: 1px;
      }

      &--4 {
        width: 4px;
        margin-left: 1px;
      }
    }
  }
}



.text {
  @include text-setting('s');
  margin: 0 0 16px;
  font-family: font-theme('text','regular');
}