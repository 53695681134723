/* ==========================================================================
   CAROUSEL
   ========================================================================== */

.carousel {
  @extend %no-list;
  padding: 0;

  @media #{$tablet} {
    padding: 0 -16px !important;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .card {
      width: 100%;
    }
  }

  &__link {
      display: block;
      width: 100%;
      height: 200px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;

      &:hover {
        .image {
          .overlay {
            opacity: 1;
          }
        }
      }

      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        
        .overlay {
          width: 100%;
          height: 100%;
          background-color: rgba(20, 20, 20, 0.8);
          text-align: center;
          opacity: 0;

          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;

          i {
            color: #fff;
            font-size: 26px;
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            display: inline-block;
          }
        }
      }
  }

  &__item {
    @media #{$tablet} { 
      padding: 0 8px;
    }
  }

  .owl-nav {
    width: 8px !important;
  }

  .owl-next {
    right: -72px !important;

    @media #{$tablet} {
      right: -42px !important;
    }
  }

  .owl-prev {
    left: -72px !important;

    @media #{$tablet} {
      left: -45px !important;
    }
  }

  .owl-stage-outer {
    margin-bottom: 24px;
  }
}
