/* ==========================================================================
   MAIN
   ========================================================================== */

.main {
   padding-top: 74px;

   @media #{$tablet} {padding-top: 86px;}
   @media #{$desktop} {padding-top: 102px;}
}

