/* ==========================================================================
   AWARDS
   ========================================================================== */


.awards {
  &-cards {
    text-align: left;
    margin: 0;

    .card {
      padding: 0;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      @media #{$tablet} {
        width: 100%;
        display: block;
      }

      @media #{$desktop} {
        width: 100%;
      }

      &__content {
        background-color: color-theme('white');
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      }

      &__grid {

      }

      &__col {
        &:first-child {
          margin-bottom: 24px;
        }

        @media #{$tablet} {
          display: inline-block;
          vertical-align: top;

          &:first-child {
            padding-right: 24px;
            width: 30%;
            margin-bottom: 0 !important;
          }

          &:last-child {
            width: 70%;
          }
        }

        &__img {
          width: 100%;
          border-radius: 8px;
        }
      }

      &__subtitle {
        @include text-setting('base');
        font-family: font-theme('text','bold');
        margin: 8px 0;

        &--light {
          font-family: font-theme('text','regular');
        }
      }

      &__author {
        @include text-setting('s');
        margin: 8px 0;

        i {
          margin-right: 6px;
        }
      }

      &__date {
        color: color-theme('black');
        font-family: font-theme('text','regular');
        @include text-setting('s');

        i {
          color: color-theme('primary');
          @include text-setting('base');
        }
      }

      .title {
        margin: 0;
      }

      .text {
        margin: 0;
      }

      &__link {
        text-decoration: none;
        @include text-setting('s');
        font-family: font-theme('text','bold');
        color: color-theme('black');
        display: block;

        i {
          @include text-setting('xl');
          line-height: 24px;
          margin-right: 12px;
          color: color-theme('primary');

          &:before {
            vertical-align: sub;
          }
        }

        &__icon {
          width: 32px !important;
          height: 32px !important;
          border-radius: 10px;
          background-color: color-theme('white');
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          display: inline-block;
          vertical-align: bottom;
          margin-right: 12px;

          i {
            @include text-setting('base');
            width: 100%;
            height: 100%;
            position: relative;

            &:before {
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -3px;
              margin-top: -8px;
            }
          }
        }

        &:hover {
          color: color-theme('primary','light');
        }

        &--video {
          margin-bottom: 16px;
        }
      }

      &__bottom {
        margin-top: 24px;
      }

      &__downloads {
        margin-top: 16px;
      }
    }
  }

  &__bottom {
    margin-top: 40px;
  }

  &-container {
    max-width: 1024px;
    margin: 0 auto;

    .table {
      // &:nth-child(even) {
      //   display: block;

      //   @media #{$tablet} {
      //     display: table;
      //   }
      // }

      &__cell {
        &:first-child { 
          @media #{$tablet} { width: 30%; }
        }
        &:last-child { 
          @media #{$tablet} { width: 70%; }
        }
      }

      &__img {
        min-height: 640px;

        @media #{$tablet} {
          min-height: 200px;
        }
      }
    }

    &__img {
      max-width:300px;
      height:auto;

      @media #{$tablet} {
        margin-top: 204px;
      }
    }
  }

  &__links {

    &__title{
      @include text-setting('base');
      font-family: font-theme('text');
      color: color-theme('black','dark');
      text-align: left;
      margin:8px 0;
    }
    

    &__item {
      display: block;
      @include text-setting('s');
      font-family: font-theme('text','bold');
      color: color-theme('black', 'base');
      text-align: left;
      text-decoration: none;

      &:hover {
        color: color-theme('primary','light');
      }

      i {
        font-size: 2.4375rem;
        line-height: 3rem;
        line-height: 24px;
        margin-right: 12px;
        color: color-theme('primary');
        vertical-align: middle;
        margin-bottom: 8px;
      }
    }
  }
}