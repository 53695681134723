/* ==========================================================================
   BUTTONS
   ========================================================================== */

.btn {
  display: inline-block;
  text-decoration: none;
	outline: none !important;
  white-space: nowrap;
  background-color: color-theme('primary');
  color: color-theme('white');
  font-family: font-theme('text','bold');
  @include text-setting('s');
  @include border-radius(10px !important);
  @include transition(.15s);
  padding: 8px 32px;

  &--outline {
    background-color: color-theme('white');
    color: color-theme('primary');
    border: 1px solid color-theme('primary');
  }

  &--transparent {
    background-color: transparent;
    color: color-theme('white');
    border: 1px solid color-theme('white');

    &.no-touch:hover,
    &:hover {
      background-color: color-theme('primary');
    }
  }

  &--small {
    padding: 4px 22px !important;
  }

  &--big {
    padding: 12px 40px;
  }

  &--block {
    display: block;
  }

  &.no-touch:hover {
    background-color: color-theme('primary','light');
    color: color-theme('white');
  }

  &.no-touch:active,
  &:active {
    background-color: color-theme('primary','light');
    color: color-theme('white');
  }
}
