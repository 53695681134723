/* ==========================================================================
   activity
   ========================================================================== */


.activity {
  &__table {
    @media #{$tablet} {
      display: table;
    }
  }

  &__cell {
    position: relative;

    @media #{$tablet} {
      display: table-cell;
      height: auto;
      vertical-align: top;
      width: 50%;
    }
  }

  &__data {
    padding: 16px 0;

    @media #{$tablet} {
      padding: 64px 32px;
    }

    @media #{$tablet} {
      padding: 96px 32px;
    }
  }

  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    border-radius: 8px;

    @media #{$tablet} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      min-height: auto;
    }
  }

  &__text {
    margin: 16px 0 0;
    @include text-setting('base');
    font-family: font-theme('text','regular');
    text-align: left;
  }

  &-bg {
    max-width: 1180px;
    margin: 0 -16px;
    position: relative;
    color: color-theme('white');

    @media #{$tablet} {
      margin: 0 auto;
    }

    &__bg {
      background-size: cover;
      background-position: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
    }

    &__content {
      position: relative;
      z-index: 19;
      max-width: 600px;
      padding: 24px;
      width: 100%;

      @media #{$tablet} {
        padding: 56px;
      }
    }

    &__title {
      margin: 0 0 16px;
      @include text-setting('xl');
      font-family: font-theme('text','medium');

      @media #{$tablet} {
        @include text-setting('xxl');
      }
    }

    &__subtitle {
      margin: 0 0 16px;
      @include text-setting('xm');
      font-family: font-theme('text','regular');

      @media #{$tablet} {
        @include text-setting('l');
      }
    }

    &__list {
      padding: 0 24px;

      &__item {
        color: color-theme('primary','active');
        @include text-setting('m');

        span {
          color: color-theme('white');
        }
      }
    }
  }

  &-img {
    width: 100%;
  }

  &__btn {
    &-container {
      padding: 16px 0;

      @media #{$tablet} {
        padding: 24px 32px;
      }
    }
  }

  //detail

  &-detail {
    font-size: 0;

    .activity {
      &__sidebar {
        margin-bottom: 32px;

        @media #{$tablet} {
          display:inline-block;
          vertical-align: top;
          width: 20%;
          margin-bottom: 0;
          position: sticky;
          top: 150px;
        } 

        &__title {
          @include text-setting('m');
          text-align: left;
          font-family: font-theme('text','bold');
          margin: 0 0 24px;
        }
      }

      &__main {
        @media #{$tablet} {
          display:inline-block;
          vertical-align: top;
          width: 80%;
          padding: 0 0 0 24px;
        }

        @media #{$desktop} {
          padding: 0 0 0 48px;
        }

        .editor {
          max-width: 100%;

          > * {
            max-width: 100%;
          }
        }
      }

      &__nav {
        &__list {
          @extend %no-list;
        }
        
        &__item {
          text-align: left;

          //state
          &.is-active {
            .activity__nav__link {
              color: color-theme('black');

              &__icon { transform: rotate(180deg); }
            }
          }
        }

        &__link {
          @include text-setting('s');
          text-decoration: none;
          color: color-theme('grey','darker');
          display: block;
          padding: 16px 16px 16px 0;
          position: relative;
          border-bottom: 1px solid color-theme('grey','light');
          font-family: font-theme('text','bold');
          transition: .2s;

          &__icon {
            position: absolute;
            top: 50%;
            margin-top: -7px;
            right: 16px;
          }

          &:hover,
          &.no-touch:hover {
            color: color-theme('primary');
          }
        }
      }

      &__subnav {
        @extend %no-list;

        &__item {
          &:first-child {
            margin-top: 16px;
          }

          &.is-active {
            .activity__subnav__link { color: color-theme('primary'); }
          }
        }

        &__link {
          @include text-setting('xs');
          text-decoration: none;
          color: color-theme('grey','darker');
          display: block;
          padding: 8px 0;

          &:hover,
          &.no-touch:hover {
            color: color-theme('primary');
          }
        }
      }

      &__downloads {
        margin: 32px 0;

        @media #{$tablet} {
          margin: 48px 0;
        }
      }

      &__cards {
        text-align: left;
        margin: 32px 0;

        @media #{$tablet} {
          margin: 48px 0;
        }

        .card {
          @media #{$tablet} {
            width: 33.333333333%;
          }
        }

        h4 {
          @include text-setting('base');
          font-family: font-theme('text', 'bold');
          color: color-theme('black');
          margin: 0 0 16px;
          text-align: left;
        }
      }

      &__audio {
        text-align: left;

        &__list {
          @extend %no-list;
        }

        &__item {
          padding: 16px;
          border-bottom: 1px solid color-theme('grey','light');

          &:first-child {
            border-top: 1px solid color-theme('grey','light');
          }

          &__title {
            @include text-setting('s');
            margin: 0 0 12px;
            color: color-theme('black');
          }
        }

        h4 {
          @include text-setting('base');
          font-family: font-theme('text', 'bold');
          color: color-theme('black');
          margin: 0 0 32px;
          text-align: left;
        }
      }

      &__carousel {
        margin: 32px 0;

        @media #{$tablet} {
          margin: 48px 0;
        }
      }
    }
  }
}
