/* ==========================================================================
   FORMS
   ========================================================================== */

.form {
  @extend %child-no-margin;
  max-width: 564px;
  margin: 0 auto;

  &__fieldset {
    @extend %child-no-margin;
    position: relative;
    padding: 0;
    margin: ($bl * 4) 0;
    border: 0;
    text-align: left;
  }

  &__label {
    display: block;
    margin-bottom: $bl;
    @include text-setting('s');
    @include transition(.15s);

    &.required {
      color: color-theme('red');
    }
  }

  &__input,
  &__textarea,
  &__select {
    display: block;
    width: 100%;
    padding: 11px 15px;
    border: 1px solid color-theme('grey');
    @include text-setting('base');
    color: color-theme('black');
    outline: none;
    background: color-theme('white');
    -webkit-appearance: none;
    @include border-radius(10px);
    @include transition(.15s);

    &:focus {
      border-color: color-theme('black');
    }

    &.required {
      border: 1px solid color-theme('red');
      background: color-theme('white');
    }

    &.disable {
      color: color-theme('grey');
      border-color: color-theme('grey');
      background: color-theme('grey');
      pointer-events: none;
    }
  }

  &__textarea {
    @extend .form__input;
    min-height: $bl * 10;
    max-height: $bl * 15;
  }

  &__select {
    padding-right: 34px;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    &-container {
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: color-theme('black') transparent transparent transparent;
        position: absolute;
        top: 50%;
        right: $bl * 2 - 1;
        margin-top: -3px;
        pointer-events: none;
        @include transition(.15s);
      }

      &.disable {
        &:before {
          color: color-theme('grey');
        }

        .form__select {
          border-color: color-theme('grey');
          pointer-events: none;
        }
      }
    }
  }

  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;

    + .form__label {
      position: relative;
      padding-left: 24px;
      @include text-setting('s');

      &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid color-theme('black');
        text-align: center;
        color: color-theme('grey');
      }
    }

    &:checked + .form__label {
      &:before {
        content: '';
        color: color-theme('black');
        background: color-theme('black');
      }
    }
  }

  &__radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .form__radio__checkmark {
        background-color: color-theme('primary');
      }

      &:checked ~ .form__radio__checkmark:after {
        display: block;
      }
    }

    &__checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: color-theme('grey');
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    :hover input ~ .form__radio__checkmark {
      background-color: color-theme('grey','light');
    }

    .form__radio__checkmark:after {
      top: 6px;
     left: 6px;
     width: 8px;
     height: 8px;
     border-radius: 50%;
     background: white;
   }
  }

  &__error {
    text-align: center;
    color: color-theme('red','base')!important;
    font-family: font-theme('text','bold');
  }
}

select::-ms-expand {
  display: none;
}
/*custom checkbox */

/* The container */
.form-check {
  display: block;
  margin-top: 16px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.form-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.form-check-checkmark {
  position: absolute;
  top: 15px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: color-theme('grey');
}

/* On mouse-over, add a grey background color */
.form-check:hover input ~ .form-check-checkmark {
  background-color: color-theme('grey','light');
}

/* When the checkbox is checked, add a blue background */
.form-check input:checked ~ .form-check-checkmark {
  background-color: color-theme('primary');
}

/* Create the checkmark/indicator (hidden when not checked) */
.form-check-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-check input:checked ~ .form-check-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-check .form-check-checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid color-theme('white');
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
