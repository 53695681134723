/* ==========================================================================
   NEWS
   ========================================================================== */


.news {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  &__date {
    @include text-setting('base');
    color: color-theme('black');
    margin: 0 0 16px;
    font-family: font-theme('italic');

    &__icon {
      margin-right: 8px;
      @include text-setting('m');
      color: color-theme('primary');
    }
  }

  &__nav {
    margin: 32px 0 0;
    
    &__list {
      @extend %no-list;
      font-size: 0;
    }

    &__item {
      display: inline-block;
      vertical-align: middle;
      width: 50%;

      .news__nav__link {
        i {
          margin-right: 16px;
          margin-left: 0;
        }
      }

      &--next {
        text-align: right;

        .news__nav__link {
          i {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }

      &.is-disable {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__link {
      @include text-setting('s');
      text-decoration: none;
      color: color-theme('grey','darker');

      &:hover,
      &.no-touch:hover {
        color: color-theme('primary');
      }
    }
  }

  &__footer {
    padding-top: 48px;
    border-top: 1px solid color-theme('grey','light');
    margin-top: 48px;

    &__title {
      @include text-setting('xm');
      color: color-theme('black');
      margin: 0 0 16px;
      font-family: font-theme('text','bold');
    }

    &__name {
      @include text-setting('m');
      color: color-theme('black');
      margin: 0;
      font-family: font-theme('text','semibold');
    }

    &__company {
      @include text-setting('s');
      color: color-theme('black');
      margin: 0 0 16px;
      font-family: font-theme('text','light');
    }

    &__list {
      @extend %no-list;

      &__item {
        &__link {
          text-decoration: none;
          color: color-theme('black');
          @include text-setting('s');

          &:hover,
          &.no-touch:hover {
            color: color-theme('primary');
          }
        }

        &__icon {
          color: color-theme('primary');
          margin-right: 8px;
        }
      }
    }

    &__item {
      margin: 32px 0;

      &:first-child { margin-top: 0; }
      &:last-child { margin-bottom: 0; }
    }
  }

  &__note {
    font-size: 24px !important;
    font-family: font-theme('text','bold') !important;

    i {
      color: color-theme('primary'); 
      font-size: 20px !important;
      margin-right: 6px;
    }

    a {
      color: color-theme('black') !important;

      &:hover,
      &.no-touch:hover {
        color: color-theme('primary') !important;
      }
    }

    &__icon { 
      color: color-theme('primary'); 
      font-size: 20px !important;
      margin-right: 6px;
    }

    &__link {
      color: color-theme('black') !important;

      &:hover,
      &.no-touch:hover {
        color: color-theme('primary') !important;
      }
    }
  }
}