/* ==========================================================================
   Variables
   ========================================================================== */

// Color theme

$color-theme: (
  'primary': (
    'base': #6B9C38,
    'light': #71BB58,
    'lighter': #9EDA89
  ),
  'red': (
    'base': #ff0000
  ),
  'white': (
    'base': #fff
  ),
  'black': (
    'base': #484848,
    'dark': #282828,
  ),
  'grey': (
    'lighter': #fafafa,
    'light': #F4F4F4,
    'base': #D6D6D6,
    'dark': #AEAEAE,
    'darker': #858585
  )
);

// Font theme

$font-theme: (
  'text': (
    'light': ('Lato light', sans-serif),
    'regular': ('Lato Regular', sans-serif),
    'bold': ('Lato Bold', sans-serif)
  ),
  'italic': (
    'regular': ('Lato Regular Italic', sans-serif),
    'bold': ('Lato Bold Italic', sans-serif)
  ),
);

// Text

$base-font-size: 1rem;
$base-line-height: 1.5rem;

$text-settings: (
  'xxxs': (
    font-size: .66rem, // 10,56px
    line-height: $base-line-height
  ),
  'xxs': (
    font-size: .6875rem, // 11px
    line-height: $base-line-height - .5
  ),
  'xs': (
    font-size: .75rem, // 12px
    line-height: $base-line-height - .25
  ),
  's': (
    font-size: .875rem, // 14px
    line-height: $base-line-height
  ),
  'base': (
    font-size: 1rem, // 16px
    line-height: $base-line-height
  ),
  'm': (
    font-size: 1.125rem, // 18px
    line-height: $base-line-height
  ),
  'xm': (
    font-size: 1.5rem, // 24px
    line-height: $base-line-height + .75
  ),
  'l': (
    font-size: 1.75rem, // 28px
    line-height: $base-line-height * 2 - .5
  ),
  'xl': (
    font-size: 2.4375rem, // 39px
    line-height: $base-line-height * 2
  ),
  'xxl': (
    font-size: 3.25rem, // 52px
    line-height: $base-line-height
  ),
  'xxxl': (
    font-size: 6rem, // 96px
    line-height: $base-line-height * 4 + .5
  ),
  'xxxll': (
    font-size: 9.25rem, // 148px
    line-height: $base-line-height * 6 + 1
  ),
  'xxxxl': (
    font-size: 15rem, // 240px
    line-height: $base-line-height * 10
  )
);

// @include text-setting('xs');



// Easings

$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1); 


// Base line

$bl: 8px;


// Media queries

$mobile: "(min-width: 23.4375em)"; // 375px
$tablet: "(min-width: 48em)"; // 768px
$desktop: "(min-width: 64em)"; // 1024px
$desktop-l: "(min-width: 85.375em)"; // 1366px
$desktop-xl: "(min-width: 105em)"; // 1680px

$retina2x: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";
$retina3x: "only screen and (-o-min-device-pixel-ratio: 9/4), only screen and (-webkit-min-device-pixel-ratio: 2.25), only screen and (min-device-pixel-ratio: 2.25), only screen and (min-resolution: 2.25dppx)";
