
/* ==========================================================================
   NAV
   ========================================================================== */

.nav {
  position: fixed;
  top: 74px;
  left: 0;
  right: 0;
  bottom: 0;
  background: color-theme('white');
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  transform: translateX(110%);
  transition: transform .3s;

  @media #{$desktop} {
    position: inherit;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background: transparent;
    display: inline-block;
    pointer-events: auto;
    opacity: 1;
    transform: inherit;
    transition: 0s;
  }

  &__list {
    @extend %no-list;
    @extend %clearfix;
    vertical-align: middle;
    background: color-theme('white');
    text-align: center;
    margin-top: 56px;

   @media #{$desktop} {
     display: inline-block;
     height: auto;
     margin-top: 0px;
   }

  }

  &__item {
    text-align: center;
    position: relative;

    &.no-touch:hover,
    &:hover,
    &.no-touch:active,
    &:active,
    &.is-active {
      .nav__link {
         color: color-theme('primary');
        @media #{$desktop} {
          &:before {
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background-color: color-theme('primary');
            left: 50%;
            bottom: 0;
            margin-left: -2px;
          }
        }
         
      }
    }

    @media #{$desktop} {
     display: inline-block;
     vertical-align: middle;
     border-bottom: none;
    }

    &--name {
      padding: 8px 10px 8px 24px;
    }
  }

  &__link {
    display: block;
    @include text-setting('m');
    padding: 12px 24px;
    text-decoration: none;
    color: color-theme('black');
    transition: color .15s;
    transition: .2s;
    position: relative;

    &.no-touch:hover,
    &:hover,
    &.no-touch:active,
    &:active,
    &.is-active {
      color: color-theme('primary');

      @media #{$desktop} {
        &:before {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: color-theme('primary');
          left: 50%;
          bottom: 0;
          margin-left: -2px;
        }
      }
    }

    &.is-active {
      .nav__link__icon {
        transform: rotate(180deg);
      }
    }

    @media #{$desktop} {
      @include text-setting('s');
      position: relative;
      padding: 8px 12px;
    }

    &__icon {
      margin-left: 6px;
      @include text-setting('xs');
    transition: .2s;
    }
  }

  &__btn-toggle {
    vertical-align: top;

    &__lines {
      position: relative;
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
      vertical-align: text-bottom;
    }

    &__line {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background: color-theme('black');
      transition: .15s;

      &--top {
        top: 0;
        left: 0;
      }

      &--middle {
        top: 50%;
        left: 0;
        margin-top: -1px;
      }

      &--bottom {
        bottom: 0;
        left: 0;
      }
    }

    // States

    &.is-active {
      .nav__btn-toggle {
        &__line {
          &--top {
            transform: translateY(7px) rotate(45deg);
          }

          &--middle {
            transform: scaleX(0);
          }

          &--bottom {
            transform: translateY(-7px) rotate(-45deg);
          }
        }
      }
    }
  }

  // States

  &.is-active {
    pointer-events: auto;
    opacity: 1;
    transition-delay: 0s;
    transform: inherit;

    .nav {
      &__list {
        transform: translateX(0);
        transition-delay: .15s;

        @media #{$desktop} {
          transform: inherit;
        }
      }
    }
  }

  &__search {
    @media #{$tablet} {
      display: inline-block;
      vertical-align: middle;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 16px 16px;

      @media #{$tablet} {
        display: table;
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        padding: 0;
        background:rgba($color: color-theme('grey', 'lighter'), $alpha: .9);
        transform: translateY(-110%);
        transition: transform .3s $easeInOutCubic;

        &.is-active {
          transform: translateY(0);
        }
      }
    }

    &__box {
      @media #{$tablet} {
        display: table-cell;
        vertical-align: middle;
        padding: 24px;
      }
    }

    &__close {
      display: none;
      position: absolute;
      top: 8px;
      right: 20px;
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 8px;
      border-radius: 20px;
      transition: .15s;
      font-size: 0;

      @media #{$tablet} {
        display: block;
      }

      @media #{$desktop} {
        top: 16px;
        right: 68px;
      }

      @media #{$desktop-l} {
        right: 50%;
        margin-right: -616px;
      }

      &.no-touch:hover {
        background: color-theme('grey', 'light');
      }

      &.no-touch:active,
      &:active {
        opacity: .7;
      }

      &__lines {
        position: relative;
        width: 24px;
        height: 24px;
        display: inline-block;
      }

      &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background: color-theme('black');
        transition: .15s;

        &--top {
          top: 0;
          left: 0;
          transform: translateY(11px) rotate(45deg);
        }

        &--bottom {
          bottom: 0;
          left: 0;
          transform: translateY(-11px) rotate(-45deg);
        }
      }
    }

    &__fieldset {
      position: relative;

      @media #{$tablet} {
        max-width: 768px;
        margin: 0 auto;
      }
    }

    &__input {
      @extend .form__input;
      padding: 7px 8px 7px 40px;
      box-sizing: border-box !important;

      &::-webkit-input-placeholder {
        color: color-theme('grey');
      }

      &::-moz-placeholder {
        color: color-theme('grey');
      }

      &:-ms-input-placeholder {
        color: color-theme('grey');
      }

      &:-moz-placeholder {
        color: color-theme('grey');
      }

      @media #{$tablet} {
        background: none;
        border: none;
        padding: 6px 8px 8px 72px;
        border-bottom: 2px solid rgba($color: color-theme('grey'), $alpha: .5);
        @include text-setting('xxl');

        &:focus {
          background: none;
          border-bottom: 2px solid color-theme('grey');
        }
      }
    }

    &__btn {
      @extend %btn;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      background-size: 16px 16px;
      padding: 0;
      opacity: .5;
      background-color: initial;

      @media #{$tablet} {
        width: 56px;
        height: 72px;
        background-size: 40px 40px;
      }

      &.no-touch:hover {
        opacity: 1;
      }

      &.no-touch:active,
      &:active {
        opacity: .3;
      }
    }
  }
}


