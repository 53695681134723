/* ==========================================================================
   action
   ========================================================================== */

.actions {
  font-size: 0;

  &__sidebar {
    margin-bottom: 32px;

    @media #{$tablet} {
      display: inline-block;
      vertical-align: top;
      width: 20%;
      margin-bottom: 0;
      position: sticky;
      top: 150px;
    }

    &__title {
      @include text-setting("xm");
      text-align: left;
      font-family: font-theme("text", "bold");
      margin: 0;
    }

    &__subtitle {
      @include text-setting("m");
      text-align: left;
      font-family: font-theme("text", "bold");
      margin: 32px 0 0;
      padding-bottom: 16px;
      border-bottom: 2px solid color-theme('primary');
    }
  }

  &__content {
    @media #{$tablet} {
      display: inline-block;
      vertical-align: top;
      width: 80%;
      padding: 0 0 0 24px;
    }

    @media #{$desktop} {
      padding: 0 0 0 48px;
    }

    .editor {
      max-width: 100%;

      > * {
        max-width: 100%;
      }
    }
  }

  &__nav {
    &__list {
      @extend %no-list;
    }

    &__item {
      text-align: left;

      //state
      &.is-active {
        .activity__nav__link {
          color: color-theme("black");

          &__icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__link {
      @include text-setting("s");
      text-decoration: none;
      color: color-theme("grey", "darker");
      display: block;
      padding: 16px 16px 16px 0;
      position: relative;
      border-bottom: 1px solid color-theme("grey", "light");
      font-family: font-theme("text", "bold");
      transition: 0.2s;

      &__icon {
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: 16px;
      }

      &--big {
        @include text-setting("m");
        color: color-theme("black");
      }

      &:hover,
      &.no-touch:hover {
        color: color-theme("primary");
      }
    }
  }

  .carousel {
    margin-top: 40px;
  }
}
