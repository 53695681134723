/* ==========================================================================
   FOOTER
   ========================================================================== */

.footer {
  background-color: color-theme('grey','darker');

  &__wrapper {
    max-width: 1180px;
    margin: 0 auto;
    padding: 40px 16px;

    @media #{$tablet} {
      padding: 40px 16px;
    }
  }

  &__grid {
    @extend %no-list;
    font-size: 0;
  }

  &__info {
    color: color-theme('white');
    padding: 16px 0;
    text-align: center;

    @media #{$tablet} {
      display: inline-block;
      vertical-align: top;
      width: 70%;
      padding: 12px 0 0;
      text-align: left;
    }
  }

  &__nav {
    position: relative;
    color: color-theme('white');
    padding: 16px 0;
    text-align: center;

    @media #{$tablet} {
      padding: 12px 48px;
      display: inline-block;
      vertical-align: top;
      width: 20%;
      text-align: left;

       &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: color-theme('white');
      }
    }

    &__list {
      @extend %no-list;

      &__item {
        &--title {
          @include text-setting('base');
          font-family: font-theme('text','bold');
          margin-bottom: 16px;
        }
      }
    }

    &__link {
      text-decoration: none;
      @include text-setting('s');
      font-family: font-theme('text','regular');
      color: color-theme('white');

      &:hover {
        color: color-theme('primary','light');
      }
    }
  }

  &__contact {
    position: relative;
    color: color-theme('white');
    padding: 16px 0;
    text-align: center;

    @media #{$tablet} {
      padding: 12px 0 12px 48px;
      display: inline-block;
      vertical-align: top;
      width: 30%;
      text-align: left;

       &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: color-theme('white');
      }
    }

    &__top {
      @include text-setting('s');
      font-family: font-theme('text','regular');
      margin: 0;
    }

    &__bottom {
      @include text-setting('s');
      font-family: font-theme('text','bold');
      margin: 0;
    }

    &__link {
      @include text-setting('s');
      font-family: font-theme('text','bold');
      margin: 0;
      text-decoration: none;
      color: color-theme('white');

      &:hover {
        color: color-theme('primary','light');
      }

      &__icon{
        @include text-setting('xl');
      }
    }

    &__btn {
      margin-top: 24px;
    }
  }

  &__title {
    @include text-setting('base');
    font-family: font-theme('text','bold');
    margin: 0 0 16px;
  }

  &__text {
    @include text-setting('s');
    font-family: font-theme('text','regular');
    margin: 0 0 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &--underline {
      text-decoration: underline;
      font-family: font-theme('text','bold');
    }
  }

  &__bottom {
    background-color: color-theme('white');

    &__wrapper {
      max-width: 1180px;
      margin: 0 auto;
    }

    &__list {
      @extend %no-list;
      padding: 0 0 24px;
      text-align: center;

      @media #{$tablet} {
        padding: 16px 0;
        display: inline-block;
        float: right;
      }
    }

    &__item {
      @media #{$tablet} {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;

        &:last-child { margin-right: 0; }
      }
    }

    &__link {
      text-decoration: none;
      color: color-theme('grey','dark');
      @include text-setting('s');
      display: block;

      &.no-touch:hover,
      &:hover {
        color: color-theme('primary');
      }
    }
  }

  &__copy {
    color: color-theme('grey','dark');
    @include text-setting('s');
    margin: 0;
    text-align: center;
    padding: 16px 0;

    @media #{$tablet} {
      display: inline-block;
    }
  }
}