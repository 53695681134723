/* ==========================================================================
   UTILITIES
   ========================================================================== */

.utilities {
  padding: 16px;
  max-width: 400px;
  margin: 0 auto;

  @media #{$tablet} {
    padding: 32px;
  } 


  &__title {
    @include text-setting('xm');
    font-family: font-theme('text','bold');
    margin: 0;

    @media #{$tablet} {
      @include text-setting('xl');
    } 
  }

  &__text {
    &:last-child {
      margin-bottom: 0;
      font-family: font-theme('text','bold');
    }
  }
}