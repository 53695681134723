/* ==========================================================================
   LIST
   ========================================================================== */

.list {
  &-download {
    @extend %no-list;
    margin: 24px 0;

    li {
      text-align: left;
      border-bottom: 1px solid color-theme('grey');

      &:first-child { border-top: 1px solid color-theme('grey'); }

      a {
        text-decoration: none;
        color: color-theme('black');
        @include text-setting('s');
        display: block;
        padding: 16px 24px;
        font-family: font-theme('text','bold');

        &:hover {
          color: color-theme('primary');
        }

        i {
          @include text-setting('l');
          margin-right: 16px;
          margin-top: -10px;

          &:before {
            vertical-align: bottom;
          }
        }
      }
    }
  }
}