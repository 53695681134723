/* ==========================================================================
   TABS
   ========================================================================== */

.table {
  margin: 32px 0;
  width: 100%;

  @media #{$tablet} {
    display: table;
    margin: 48px 0;
  }

  &:nth-child(even) {
    display: flex;
    flex-direction: column-reverse;

    @media #{$tablet} {
      display: table;
    }
  }

  &--even {
    display: flex;
    flex-direction: column-reverse;

    @media #{$tablet} {
      display: table;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__cell {
    position: relative;

    @media #{$tablet} {
      display: table-cell;
      height: auto;
      vertical-align: top;
      width: 50%;
    }

    &--form {
      @media #{$tablet} {
        padding-right: 48px;
      }
    }
  }

  &__data {
    padding: 16px 0;

    @media #{$tablet} {
      padding: 48px 32px;
    }
  }

  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    border-radius: 8px;

    @media #{$tablet} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      min-height: auto;
    }
  }

  &__map {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    max-height: 300px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 32px;

    @media #{$tablet} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      margin-bottom: 0;
    }
  }

  &__text {
    margin: 16px 0 0;
    @include text-setting('base');
    font-family: font-theme('text','regular');
    text-align: left;
  }

  &-bg {
    max-width: 1180px;
    margin: 0 -16px;
    position: relative;
    color: color-theme('white');

    @media #{$tablet} {
      margin: 0 auto;
    }

    &__bg {
      background-size: cover;
      background-position: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
    }

    &__content {
      position: relative;
      z-index: 19;
      max-width: 600px;
      padding: 24px;
      width: 100%;

      @media #{$tablet} {
        padding: 56px;
      }
    }

    &__title {
      margin: 0 0 16px;
      @include text-setting('xl');
      font-family: font-theme('text','medium');

      @media #{$tablet} {
        @include text-setting('xxl');
      }
    }

    &__subtitle {
      margin: 0 0 16px;
      @include text-setting('xm');
      font-family: font-theme('text','regular');

      @media #{$tablet} {
        @include text-setting('l');
      }
    }

    &__list {
      padding: 0 24px;

      &__item {
        color: color-theme('primary','active');
        @include text-setting('m');

        span {
          color: color-theme('white');
        }
      }
    }
  }

  &-img {
    width: 100%;
  }

  &__btn {
    &-container {
      padding: 16px 0;

      @media #{$tablet} {
        padding: 24px 32px;
      }
    }
  }
}