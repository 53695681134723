/*
 *  Owl Carousel - Custom
 */

.owl-carousel {
  text-align: center;
}

.owl-dots {
  display: inline-block;
  position: relative;
  z-index: 10;
}

.owl-dot {
  width: 10px;
  height: 10px;
  background: color-theme('grey','light');
  float: left;
  margin: 0 3px;

  @include border-radius(50%);

  &.active {
    background: color-theme('primary');
  }
}

.owl-nav__icon {
  font-size: 1.625em;
  line-height: 1em;
  color: color-theme('primary');
}

.disabled .owl-nav__icon {
  color: color-theme('grey','light');
}

.owl-nav {
  height: 30px;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: -5px;
  z-index: 0;

  @include transform(translate(-50%,0));
}

.owl-prev {
  position: absolute;
  top: 0;
  left: -25px;
}

.owl-next {
  position: absolute;
  top: 0;
  right: -25px;
}
