/* ==========================================================================
   CARD
   ========================================================================== */


.card {
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  padding: 8px;
  width: 100%;
  text-align: left;
  position: relative;

  @media #{$tablet} {
    padding: 16px;
    width: 50%;
  }

  @media #{$desktop} {
    padding: 16px;
    width: 25%;
  }

  &:last-child {
    .card__points { display: none; }
  }

  &__figure {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    margin: 0;
    padding-bottom: 56.25%; // 16:9
    overflow: hidden;
    background: color-theme('black');
    border-radius: 4px 4px 0 0;

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: .3;

      width: 100%;
      height: auto;

      max-height: none;
      max-width: none;

      min-height: 100%;
      min-width: 100%;

      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);

      transition: .2s;
    }

    &.no-touch:hover,
    &:hover {
      .card__figure__img { opacity: .5; }
    }

    &.no-touch:active,
    &:active {
      .card__figure__img { opacity: .5; }
    }
  }

  &__content {
    @extend %child-no-margin;
    padding: 16px;
    border-radius: 8px;
    background-color: color-theme('white');
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    @media #{$tablet} { padding: 20px; }

    > * {
      margin: 16px 0;
    }
  }

  &__info {
    @extend %no-list;
    font-size: 0;

    &__item {
      position: relative;
      @include text-setting('xxs');
      display: inline-block;
      padding-right: 16px;

      &:before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        width: 1px;
        right: 8px;
        background: color-theme('primary', 'active');
      }

      &:last-child {
        padding-right: 0;

        &:before { display: none; }
      }
    }

    &__link {
      @extend %btn;
      color: color-theme('grey', 'dark');
      transition: .15s;

      &.no-touch:hover {
        color: color-theme('primary', 'active');
      }

      &.no-touch:active,
      &:active {
        color: color-theme('primary', 'sky');
      }
    }
  }

  &__btn {
    margin: 24px 0 0;
    display: block;
    text-align: center;
    padding: 4px 22px !important;

    &--download {
      text-decoration: none;
      color: color-theme('black');
      @include text-setting('xxs');
      display: block;
      padding: 0 0 0 32px !important;
      font-family: font-theme('text','regular');
      text-align: left;
      margin: 0;
      position: relative;

      &:hover {
        color: color-theme('primary');
      }

      i {
        @include text-setting('l');
        position: absolute;
        top: -10px;
        left: 0;

        &:before {
          vertical-align: bottom;
        }
      }
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 20px;
    text-decoration: none;
    color: color-theme('white');
  }

  &__points {
    @extend %no-list;

    @media #{$tablet} {
      position: absolute;
      right: -14px;
      top: 50%;
      margin-top: -3px;
    }
  }

  &__point {
    @media #{$tablet} {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      margin-right: 4px;
      border-radius: 16px;

      &:last-child {
        margin-right: 0;
      }

      &--1 {
        background-color: color-theme('primary');
      }

      &--2 {
        background-color: color-theme('primary','light');
      }

      &--3 {
        background-color: color-theme('primary','lighter');
      }
    }
  }

  &__date {
    @include text-setting('s');
    color: color-theme('primary');
    margin: 0;
    font-family: font-theme('italic');

    &__icon {
      margin-right: 8px;
      @include text-setting('base');
    }
  }

  &__img {
    &__img {
      width: 100%;
    }
  }

  &__text {
    font-family: font-theme('text','regular') !important;
    margin-top: 16px;
  }

  &__more {
    margin: 24px 0 0;

    &__btn {
      @include text-setting('s');
      display:inline-block;
      color: color-theme('grey','dark');
      text-decoration: none;
      font-family: font-theme('text','bold');
      transition: .2s;

      &:hover,
      &.no-touch:hover {
        color: color-theme('primary');
      }
    }
  }

  &__video {
    width: 100%;

    &__title {
      @include text-setting('m');
      margin: 16px 0;
      color: color-theme('black');
      font-family: font-theme('text','bold');
    }
  }

  .iframe {
    @extend %iframe;
    margin: 0 auto;

    &__wrapper {
      @extend %iframe__wrapper;
      margin: 0 auto;
    }
  }
}

.cards {
  font-size: 0;

  @media #{$tablet} {
    margin: 0 -16px;
  }

  &--news {
    @media #{$tablet} {
      margin: 0 -20px;
    }

    .card {
      padding: 20px;

      &__content {
        border-radius: 4px;
      }

      &__points {
        display: none;

        @media #{$tablet} {
          display: block;
        }
      }

      &__img {

        &__img {
          border-radius: 8px;
        }
      }

      @media #{$tablet} {
        &:nth-child(2) {
          .card__points {
            display: none;
          }
        }
      }

      @media #{$desktop} {
        &:nth-child(2) {
          .card__points {
            display: block;
          }
        }
      }

      &__data {
        .text {
          font-family: font-theme('text','bold');
        }
      }

      .title {
        margin-top: 16px;
        @include text-setting('m');
      }

      &__aboutus {
        &__title {
          @include text-setting('s');
          font-family: font-theme('text','bold') !important;
          margin: 0;
        }

        &__subtitle {
          @include text-setting('xs');
          font-family: font-theme('text','regular') !important;
          margin: 0;
        }
      }
    }
  }

  &--aboutus {
    text-align: left;
    margin: 0;

    .card {
      @media #{$tablet} {
        padding: 20px;
      }

      &__content {
        border-radius: 4px;
      }

      &__row {
        @media #{$tablet} {
          margin: 0 -20px;
        }
      }

      &__img {
        &__img {
          border-radius: 8px;
        }
      }

      &__data {
        .text {
          font-family: font-theme('text','bold');
        }
      }

      .title {
        margin-top: 16px;
        @include text-setting('m');
      }

      &__aboutus {
        &__title {
          @include text-setting('s');
          font-family: font-theme('text','bold') !important;
          margin: 0;
        }

        &__subtitle {
          @include text-setting('xs');
          font-family: font-theme('text','regular') !important;
          margin: 0;
        }
      }
    }
  }

  &--big {
    .card {
      @media #{$tablet} {
        width: 33.3333333%;
      }
    }
  }


  .card-new {
    &__title {
      font-family: font-theme('text','bold');
      color: color-theme('black');
      margin: 0 0 12px;
      @include text-setting('s');
      position: relative;
      left: 0;
      text-decoration: none;
      display: block;

      &:hover,
      &.no-touch:hover {
        color: color-theme('primary');
      }

      &--nolink {
        pointer-events: unset;

        &:hover,
        &.no-touch:hover {
          color: color-theme('black');
        }
      }
    }

    &__img {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      margin: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
      background: #ffffff;
      border-radius: 4px 4px 0 0;
      text-decoration: none;

      &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        max-height: none;
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        transition: .2s;
      }
    }
  }

  &--reconocimientos {
    .card {
      @media #{$desktop} {
        padding: 16px;
        width: 50%;
      }

      &__date {
        @include text-setting('base');
        color: color-theme('primary');
        margin: 0 0 16px;
        font-family: font-theme('text','bold');

        &__icon {
          margin-right: 8px;
          @include text-setting('m');
        }
      }
    }

    .card-new {
      &__title {
            @include text-setting('m');

          @media #{$tablet} {
            @include text-setting('xm');
          }
      }
    }
  }

  &--news-2col {
    .card {
      @media #{$desktop} {
        padding: 16px;
        width: 50%;
      }

      &__date {
        @include text-setting('base');
        color: color-theme('primary');
        margin: 0 0 16px;
        font-family: font-theme('text','bold');

        &__icon {
          margin-right: 8px;
          @include text-setting('m');
        }
      }
    }

    /* .card-new {
      &__title {
            @include text-setting('m');

          @media #{$tablet} {
            @include text-setting('xm');
          }
      }
    } */
  }
}
