/* ==========================================================================
   PUBLICATIONS
   ========================================================================== */


.publications {
  &-cards {
    text-align: center;

    .card {
      @media #{$tablet} {
        width: 100%;
        display: inline-block;
      }

      /* @media #{$desktop} {
        width: 50%;
      } */

      &__content {
        background-color: color-theme('white');
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

      }

      &__grid {
        display: flex;
        flex-direction: column-reverse;

        @media #{$tablet} {
          display: block;
        }
      }

      &__col {
        &:last-child {
          margin-bottom: 24px !important;
        }

        @media #{$tablet} {
          display: inline-block;
          vertical-align: top;
          width: 50%;

          &:first-child {
            padding-right: 20px;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        &__img {
          width: 100%;
        }
      }

      &__subtitle {
        @include text-setting('base');
        font-family: font-theme('text','bold');
        margin: 8px 0;
      }

      &__author {
        @include text-setting('s');
        margin: 8px 0;

        i {
          margin-right: 6px;
        }
      }

      .title {
        margin: 0;
      }

      .text {
        margin: 0;
      }

      &__link {
        text-decoration: none;
        @include text-setting('s');
        font-family: font-theme('text','bold');
        color: color-theme('black');

        i {
          @include text-setting('xl');
          line-height: 24px;
          margin-right: 6px;
          color: color-theme('primary');

          &:before {
            vertical-align: sub;
          }
        }

        &:hover {
          color: color-theme('primary','light');
        }
      }

      &__bottom {
        margin-top: 24px;
      }
    }
  }
}