/* ==========================================================================
   Fonts
   ========================================================================== */

   @font-face {
    font-family: 'Lato light';
    src: url('../assets/fonts/Lato-Light.eot');
    src: url('../assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Light.woff2') format('woff2'),
        url('../assets/fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold';
    src: url('../assets/fonts/Lato-Bold.eot');
    src: url('../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('../assets/fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Regular Italic';
    src: url('../assets/fonts/Lato-Italic.eot');
    src: url('../assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Italic.woff2') format('woff2'),
        url('../assets/fonts/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato Regular';
    src: url('../assets/fonts/Lato-Regular.eot');
    src: url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('../assets/fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold Italic';
    src: url('../assets/fonts/Lato-BoldItalic.eot');
    src: url('../assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

