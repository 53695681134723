/* ==========================================================================
   SEARCH
   ========================================================================== */

.search {
  @extend %wrapper--xxs;

  &__fieldset {
    @extend .nav__search__fieldset;
  }

  &__input {
    @extend .form__input;
    box-sizing: border-box !important;
    background: none;
    border: none;
    padding: 6px 8px 8px 40px;
    border: 2px solid rgba($color: color-theme('grey'), $alpha: .5);
    @include text-setting('base');

    @media #{$tablet} {
      @include text-setting('xm');
      padding: 6px 8px 8px 56px;
    }

    &::-webkit-input-placeholder {
      color: color-theme('grey');
    }

    &::-moz-placeholder {
      color: color-theme('grey');
    }

    &:-ms-input-placeholder {
      color: color-theme('grey');
    }

    &:-moz-placeholder {
      color: color-theme('grey');
    }

    &:focus {
      background: none;
      border-bottom: 2px solid color-theme('grey');
    }

    &__clear {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 32px;
      height: 32px;
      text-align: center;
      padding: 8px;
      border-radius: 50%;
      transition: .15s;
      font-size: 0;

      @media #{$tablet} {
        width: 40px;
        height: 40px;
        top: 8px;
        right: 8px;
      }

      &.no-touch:hover {
        background: color-theme('grey', 'light');
      }

      &.no-touch:active,
      &:active {
        opacity: .7;
      }

      &__lines {
        position: relative;
        width: 16px;
        height: 16px;
        display: inline-block;

        @media #{$tablet} {
          width: 24px;
          height: 24px;
        }
      }

      &__line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background: color-theme('black');
        transition: .15s;

        &--top {
          top: 7px;
          left: 0;
          transform: rotate(45deg);

          @media #{$tablet} { top: 11px; }
        }

        &--bottom {
          bottom: 7px;
          left: 0;
          transform: rotate(-45deg);

          @media #{$tablet} { bottom: 11px; }
        }
      }
    }
  }

  &__btn {
    @extend .nav__search__btn;

    @media #{$tablet} {
      width: 56px;
      height: 56px;
      background-size: 24px 24px;
    }
  }

  &__result {
    padding-top: 24px;

    @media #{$tablet} { padding-top: 40px; }

    &__item {
      @extend .card;
      padding: 0;
      margin-bottom: 16px;

      @media #{$tablet} {
        display: table;
        width: 100%;
        margin-bottom: 32px;

        > * {
          display: table-cell;
          vertical-align: middle;
        }
      }

      &:last-child { margin-bottom: 0; }
    }

    &__figure {
      @extend .card__figure;

      @media #{$tablet} {
        width: 37.5%;
        padding: 0;
      }

      &__img {
        @extend .card__figure__img;

        @media #{$tablet} {
          width: auto;
          height: 100%;
        }
      }
    }

    &__content {
      @extend .card__content;

      @media #{$tablet} {
        padding: 40px;
      }
    }

    &__info {
      @extend .card__info;

      &__item {
        @extend .card__info__item;
      }

      &__link {
        @extend .card__info__link;
      }
    }

    &__title {
      @include text-setting('m'); 
      font-family: font-theme('text','bold');
      color: color-theme('black','base');
      text-decoration: none;
      display: block;

      @media #{$desktop} { 
        @include text-setting('l'); 
      }
    }
    
    &__btn {
      display: inline-block;
      text-decoration: none;
      outline: none !important;
      white-space: nowrap;
      background-color: color-theme('primary');
      color: color-theme('white');
      font-family: font-theme('text','bold');
      @include text-setting('s');
      @include border-radius(10px !important);
      @include transition(.15s);
      padding: 8px 32px;
    }
  }
}
