/* ==========================================================================
   EDITOR
   ========================================================================== */

.editor {
  @extend %child-no-margin;
  font-size: 0;
  max-width: 860px;
  margin: 0 auto;
  text-align: left;

  > * {
    margin-top: 0;
    margin-bottom: 32px;
    @extend .section__wrapper--narrow;
    @include text-setting('base');
    color: color-theme('black');

    @media #{$desktop} {
      margin-bottom: 40px;
      @include text-setting('base');
    }
  }

  h1, h2, h3, h4, h5, h6 { color: color-theme('black'); }

  h3 {
    @include text-setting('m');
    font-family: font-theme('text', 'medium');
  }

  h1, h2 {
    @include text-setting('m');
    font-family: font-theme('text', 'bold');
    color: color-theme('black');
    margin: 0 0 24px;
    text-align: left;

    @media #{$tablet} { @include text-setting('xm'); }
  }


  h3 {
    @include text-setting('m');
    font-family: font-theme('text', 'medium');
    color: color-theme('black');
    margin: 0 0 24px;
    text-align: left;

    @media #{$tablet} { @include text-setting('xm'); }
  }

  h4 {
    @include text-setting('base');
    font-family: font-theme('text', 'bold');
    color: color-theme('black');
    margin: 0 0 16px;
    text-align: left;
  }

  p, ol, ul, dl, table {
    color: color-theme('black');

    strong, u, b {
      color: color-theme('black');
    }

    u {
      position: relative;
      text-decoration: none;
      background-image: linear-gradient(120deg, color-theme('primary', 'light') 0%, color-theme('primary', 'light') 100%);
      background-repeat: no-repeat;
      background-size: 100% 8px;
      background-position: 0 95%;
      transition: background-size 0.25s ease-in;
    }
  }

  p { 
    margin: 24px 0;
    @include text-setting('s');
  }

  ul, ol {
    @extend %child-no-margin;
    padding-inline-start: 24px;

    li {
      margin: 8px 0;
      color: color-theme('primary');
      @include text-setting('s');

      span {
        color: color-theme('black')
      }
    }
  }

  figure,
  picture,
  img {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    @extend .scale-max-width;
    border-radius: 10px;
  }

  video {
    @extend .scale-width;
  }

  figcaption {
    @include text-setting('s');
    color: color-theme('grey');
    margin-top: 16px;
  }

  table {
    position: relative;
    height: auto!important;
    margin-bottom: 10px;
    width: 100%;

    td,
    th {
      border: 2px solid color-theme('white');
      @include text-setting('s');
      padding: 8px;
      background-color: color-theme('primary','darkest');

      @media #{$tablet} {
        padding: 16px;
      }

      img {
        display: inline-block;
        margin: 0 4px;
      }

      &:first-child { border-left: 0 none; }
      &:last-child { border-right: 0 none; }
    }

    th {
      border-top: none;
    }

    td {
      @extend %child-no-margin;

      > * { margin: 16px 0; }
    }

    tr:first-child {
      th {
        font-family: font-theme('text', 'regular');
        color: color-theme('black');
      }
    }

    // tr:last-child {
    //   td {
    //     // border-bottom:2px solid color-theme('white');
    //   }
    // }
  }

  a {
    text-decoration: none;
    color: color-theme('primary');
    @include text-setting('s');

    &:hover {
      color: color-theme('primary','light');
    }
  }

  .table-container {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: color-theme('black');
    }
  }

  .iframe {
    @extend %iframe;

    &__wrapper {
      @extend %iframe__wrapper;
    }
  }



  .no-figure {
    display: inline-block;
  }
}

.vp-center .player .vp-controls-wrapper .vp-controls .play-bar .logo {
  display: none !important;
}
