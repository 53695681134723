/* ==========================================================================
   HELPERS
   ========================================================================== */

/* HTML HELPERS */

// States

.is-hidden {
  display: none !important;
  visibility: hidden;
}

.is-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.is-visuallyhidden.focusable:active,
.is-visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.is-invisible {
  visibility: hidden;
}

// Responsive

.scale-max-width {
  max-width: 100%;
  height: auto;
}

.scale-width {
  width: 100%;
  height: auto;
}

/* SASS HELPERS */

// Clearfix

%clearfix:before,
%clearfix:after {
  content: " ";
  display: table;
}

%clearfix:after {
  clear: both;
}

%clearfix {
  *zoom: 1;
}

// Childs

%child-no-margin {
  > *:first-child { margin-top: 0 !important; }
  > *:last-child { margin-bottom: 0 !important; }
}

// Remove

%no-link {
  display: inline-block;
  color: color-theme('black', 'base');
  text-decoration: none;
}

%no-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

//img

%scale-width {
  width: 100%;
  height: auto;
}

// Wrapper

%wrapper {
  max-width: 1180px;
  margin: 0 auto;

  &--narrow {
    max-width: 800px;
  }

  &--s {
    max-width: 1175px;
    margin-left: auto;
    margin-right: auto;
  }

  &--xs {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  &--xxs {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

//iframe

%iframe {
  max-width: 1024px;
  margin: 32px auto;

  &__wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


//box

.box {
  border-radius: 10px;
  background-color: color-theme('white');
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}



//text

.is-align-left {
  text-align: left !important;
}


//color

.is-primary { color: color-theme('primary'); }


// Btns

%btn {
  display: inline-block;
  text-decoration: none;
  border: none;
  outline: none;
  border-radius: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}