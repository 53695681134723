/* ==========================================================================
   ICONS
   ========================================================================== */

.icon {
   display: inline-block;
   vertical-align: middle;
   
   &--search {
      width: 16px;
      height: 16px;
      background-image: url(../assets/img/icons/search.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
   }
}