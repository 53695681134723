/* ==========================================================================
   TOGGLE
   ========================================================================== */


.toggle {
  &__content {
    display: none;
    padding: 16px;
    background-color: color-theme('grey','lighter');

    @media #{$tablet} {
      padding: 24px;
    }

    &__text {
      @include text-setting('base');
      margin: 0;
      color: color-theme('black');
      font-family: font-theme('text','bold');
    }

    //States

    &.is-active {
      display: block;
    }
  }

  &__btn {
    border-bottom: 1px solid color-theme('grey','light');
    display: block;
    padding: 12px 16px 12px 0;
    text-decoration: none;
    color: color-theme('black');
    @include text-setting('s');
    font-family: font-theme('text','bold');
    position: relative;

    &__icon {
      margin-left: 12px;
      @include text-setting('s');
      color: color-theme('grey','darker');

      &:before {
        transition: .15s;
      }
    }

    //States

    &.is-active {
      border-bottom: 1px solid color-theme('grey','light');

      .toggle__btn__icon {
        transform: rotate(180deg);
      }
    }
  }
}
