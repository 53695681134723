/* ==========================================================================
   CONTACT
   ========================================================================== */

.contact {
  &__list {
    @extend %no-list;
    text-align: left;
    margin-top: 24px;

    &__item {
      margin: 16px 0;
      color: color-theme('primary');
      font-family: font-theme('text','bold');

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__icon {
      margin-right: 12px;
      @include text-setting('m');
    }

    &__link {
      text-decoration: none;
      font-family: font-theme('text','bold');

      &:hover {
        color: color-theme('primary','light');
      }
    }
  }

  &__form {
    padding: 16px;

    @media #{$tablet} {
      padding: 32px;
    }

    .form {
      &__fieldset {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .btn {
      color: color-theme('white')!important;
      text-align:center;
    }
  }
}