/* ==========================================================================
   Base Styles
   ========================================================================== */

html {
  font-size: 1em;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: font-theme();
  height: 100%;

  &.noscroll {
    width: 100%;
    position: fixed;
  }
}

body {
  height: 100%;
  background: color-theme('white');
  
  &.noscroll {
    width: 100%;
    position: fixed;
  }
}

::-moz-selection {
  background: color-theme();
  text-shadow: none;
}

::selection {
  background: color-theme();
  text-shadow: none;
  color: color-theme('white');
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eee;
  margin: ($bl * 6) 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browsehappy {
  margin: 0;
  background: #ccc;
  color: #000;
  padding: $bl * 3;
}

a, button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  color: color-theme();
}

* {
  @include box-sizing(border-box);
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
