/* ==========================================================================
   SLIDESHOW
   ========================================================================== */

.slideshow {
  background: color-theme('black');

  &__wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    // padding: 0 16px;
    // max-width: 1920px;

    // @media #{$tablet} {
    //   padding: 0 32px;
    // }

    // @media #{$desktop-l} {
    //   padding: 0 72px;
    // }
  }

  &__item {
    position: relative;
    display: block;
    overflow: hidden;

    height: 100% !important;


    &__fig {
      margin: 0;
      background-size: auto 100%;
      background-position: center center;

      @media #{$tablet} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__img {
      &--portrait {
        @extend %scale-width;

        @media #{$tablet} {
          display: none;
        }
      }
    }

    &__links {
      position: relative;
      margin-top: 8px;
      //background: color-theme('white');

      @media #{$tablet} {
        display: none;
      }
    }

    &__link {
      display: block;
      @include text-setting('s');
      font-family: font-theme('text','bold');
      text-decoration: none;
      color: color-theme('black');
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid color-theme('grey', 'dark');

      &:last-child {
        margin-bottom: 0;
      }

      &--terms {
        position: absolute;
        top: -48px;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 8px;
        margin: 0;
        border: none;
        text-align: center;

        &:active {
          .slideshow__item__link__icon {
            background: color-theme('primary');
          }
        }
      }

      &__icon {
        text-align: center;
        display: inline-block;
        width: 24px;
        line-height: 1em;
        padding: 6px 0 4px;
        color: color-theme('white');
        background: color-theme('primary');
        border-radius: 50%;
      }
    }

    &__ratio {
      @media #{$tablet} {
        position: relative;
        max-width: 980px;
        margin: 0 auto;

        &:before {
          content: '';
          display: block;
          height: 0;
          padding-bottom: 40.8163265%;
        }
      }
    }

    &__btn {
      display: none;

      @media #{$tablet} {
        display: block;
        position: absolute;
        z-index: 100;
        left: 50%;
        top: 50%;
        border: 0;
        outline: 0;
        //background: rgba(213, 32, 219, 0.5);
        background: transparent;
      }
    }

    // Content custom
    &__custom {
      @extend %no-link;
      position: relative;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media #{$tablet} {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 0;
      }

      &__title {
        color: color-theme('white');
        @include text-setting('l');
        padding-right: 25%;
        margin-top: 0;
        font-family: font-theme('text','bold');
        width: auto;

        @media #{$tablet} {
          @include text-setting('xl');
        }
      }

      &__subtitle {
        color: color-theme('white');
        font-family: font-theme('text','regular');
        @include text-setting('base');
        margin: 0 !important;
        width: auto;
        padding-right: 25%;

        @media #{$desktop} {
          @include text-setting('xm');

          padding-right: 40%;
        }
      }

      &__btn {
        @extend .btn;
        background: color-theme('primary');
        display: none;

        &:hover {
          color: color-theme('primary');
        }

        @media #{$tablet} {
          display: inline-block;
        }
      }

      &__content {
        @extend %child-no-margin;
        padding: 0;
        max-width: 1366px;
        width: 100%;
        margin: 0 auto;
        height: 100vh;
        display: table;

        &__data {
          width: 100%;
          right: 0;
          display: table-cell;
          vertical-align: top !important;
          padding: 120px 32px;

          @media #{$tablet} {
            padding: 140px 86px;
          }
        }

        // @media #{$tablet} {
        //   padding: 0 120px;
        // }

        // @media #{$desktop} {
        //   padding: 0 200px;
        // }

        > * {
          @media #{$tablet} {
            margin-bottom: 32px;
          }
        }

        // @media #{$tablet} {
        //   height: 100%;
        //   padding: 210px 180px;
        // }

        @media #{$desktop} {
          > * {
            margin-bottom: 40px
          }
        }
      }

      &__bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center bottom;

        @media #{$tablet} {
          background-position: center center;
        }

        &--mobile {
          @media #{$tablet} {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @media #{$tablet} {
            display: block;
          }
        }
      }

      &--bg-light {
        .slideshow__item__custom__title {
          color: color-theme('black');
        }

        .slideshow__item__custom__subtitle {
          color: color-theme('black');
        }
      }
    }
  }

  &__nav {
    position: absolute;
    z-index: 9;
    left: 8px;
    right: 8px;
    pointer-events: none;
    top: 50%;
    margin-top: -90px;

    @media #{$tablet} {
      top: 50%;
      left: 32px;
      right: 32px;
      max-width: 1286px;
      margin: -24px auto 0;
      transform: inherit;
    }

    @media #{$desktop-l} {
      left: 72px;
      right: 72px;
    }

    &:before {
      content: '';
      display: block;
      height: 0;
      padding-top: 63.44%;
      //background: rgba(233, 233, 233, .7);

      @media #{$tablet} {
        padding-top: 40.8163265%;
        display: none;
      }
    }

    &__btn {
      position: absolute;
      top: 50%;
      margin-top: -16px;
      display: inline-block;
      vertical-align: middle;
      font-size: 32px;
      width: 32px;
      height: 32px;
      text-align: center;
      color: color-theme('white');
      pointer-events: auto;

      @media #{$tablet} {
        position: relative;
        top: auto;
        margin-top: 0;
        font-size: 56px;
        width: 48px;
        height: 48px;
      }

      &--prev {
        left: 0;

        @media #{$tablet} {
          left: auto;
          float: left;
        }
      }

      &--next {
        right: 0;
        float: right;

        @media #{$tablet} {
          right: auto;
          float: right;
        }
      }

      &__icon,
      &__icon:before {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        vertical-align: top;

        @media #{$tablet} {
          height: 48px;
          line-height: 48px;
        }
      }

      &:hover {
        .slideshow__nav__btn__icon {
          color: color-theme('primary');
        }
      }
    }
  }

  // Swiper

  .swiper {
    // &-container {
    //   // padding-bottom: 58px;

    //   // @media #{$tablet} {
    //   //   padding-bottom: 112px;
    //   // }
    // }

    &-slide {
      overflow: hidden;
      width: 100% !important;
      height: 100vh !important;
      max-height: 450px !important;
    }

    &-pagination {
      bottom: 24px !important;

      @media #{$tablet} {
        bottom: 40px !important;
      }

      &-bullet {
        width: 10px;
        height: 10px;
        background: color-theme('white');
        border: 1px solid color-theme('grey', 'dark');
        opacity: 1;

        @media #{$tablet} {
          width: 12px;
          height: 12px;
        }

        &-active {
          background: color-theme('primary', 'light');
          border-color: color-theme('primary', 'light');
        }
      }

      &-play {
        position: relative;
        display: none;
        margin: 0 4px;
        vertical-align: top;
        font-size: 0;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: top;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent color-theme('grey', 'dark');

          @media #{$tablet} {
            border-width: 6px 0 6px 9px;
          }
        }

        &:after {
          position: absolute;
          top: 2px;
          left: 1px;
          content: '';
          display: inline-block;
          vertical-align: top;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3px 0 3px 4px;
          border-color: transparent transparent transparent color-theme('white');

          @media #{$tablet} {
            border-width: 4px 0 4px 6px;
          }
        }

        // States

        &.is-active {
          display: inline-block;
        }
      }

      &-pause {
        position: relative;
        display: inline-block;
        display: none;
        margin: 0 4px;
        vertical-align: top;
        font-size: 0;

        &:before {
          content: '';
          display: inline-block;
          margin-right: 1px;
          vertical-align: top;
          width: 1px;
          height: 8px;
          background: color-theme('white');
          border: 1px solid color-theme('grey', 'dark');

          @media #{$tablet} {
            width: 2px;
            height: 10px;
            margin-right: 2px;
          }
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: top;
          width: 1px;
          height: 8px;
          background: color-theme('white');
          border: 1px solid color-theme('grey', 'dark');

          @media #{$tablet} {
            width: 2px;
            height: 10px;
          }
        }

        // States

        &.is-active {
          display: inline-block;
        }
      }
    }
  }
}

