/* ==========================================================================
   SECTION
   ========================================================================== */

.section {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: color-theme("white");

  &--bg-grey {
    background-color: color-theme("grey", "light");
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1180px;
    padding: 64px 16px;

    @media #{$desktop} {
      padding: 96px 16px;
    }

    &--narrow {
      max-width: 800px;
    }
  }
}
